
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    success: {
      type: Boolean,
      required: true,
    },
  },
});
