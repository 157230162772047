import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: "copy-button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copy && _ctx.copy(...args)))
  }, [
    (_ctx.copySuccess)
      ? (_openBlock(), _createBlock(_component_fa_icon, {
          key: 0,
          icon: ['far', 'check']
        }))
      : (_openBlock(), _createBlock(_component_fa_icon, {
          key: 1,
          icon: ['far', 'copy']
        }))
  ]))
}