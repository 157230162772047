
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      copySuccess: false,
    };
  },
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  methods: {
    async copy() {
      await window.navigator.clipboard.writeText(this.content);

      this.copySuccess = true;
      setTimeout(() => this.setCopySuccess(false), 1000);
    },
    setCopySuccess(copySuccess: boolean) {
      this.copySuccess = copySuccess;
    },
  },
});
