import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_ctx.success)
    ? (_openBlock(), _createBlock(_component_fa_icon, {
        key: 0,
        class: "success",
        icon: ['far', 'check']
      }))
    : (_openBlock(), _createBlock(_component_fa_icon, {
        key: 1,
        class: "danger",
        icon: ['far', 'times']
      }))
}