
import { mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { IPlayer } from '../../../entities/player';
import { getPlayer } from '@/services/steamcord';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';
import { formatDate } from '@/util/formatDate';
import { getRandomInt } from '@/util/getRandomInt';
import CheckOrTimes from '@/components/CheckOrTimes.vue';
import CopyButton from '@/components/CopyButton.vue';

interface IData {
  player: IPlayer | undefined;
}

export default defineComponent({
  async beforeRouteEnter(to, from, next) {
    const { currentOrg } = useOrgStore();
    if (!currentOrg) {
      next({ name: 'Internal Server Error' });
      return;
    }

    const player = await getPlayer(currentOrg.id, to.params.id.toString());
    if (!player) {
      next({ name: 'Not Found' });
      return;
    }

    next((vm) => {
      (vm as any).setPlayer(player);
    });
  },
  components: {
    CheckOrTimes,
    CopyButton,
  },
  computed: {
    ...mapState(useOrgStore, ['currentOrg']),
    ...mapWritableState(useMainStore, ['loading']),
    formattedDate(): string | undefined {
      if (!this.player) {
        return undefined;
      }

      return formatDate(this.player.createdDate as Date);
    },
  },
  data(): IData {
    return {
      player: undefined,
    };
  },
  methods: {
    getRandomInt(min: number, max: number) {
      return getRandomInt(min, max);
    },
    setPlayer(player: IPlayer) {
      this.player = player;
    },
  },
  mounted() {
    this.loading = false;
  },
});
